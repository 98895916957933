<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBContainer class="mt-3" bg="white">
    <MDBRow>
      <MDBCol>
        <MDBCard>
          <MDBCardBody>
            <form @submit="Submit">
              <MDBRow>
                <MDBCol class="text-end">
                  <MDBBtn color="primary" type="submit" :disabled="SubmitLoading">
                    {{ $t("COMMON.SAVE") }}
                    <span
                      class="spinner-border Loading"
                      :class="{ 'd-none': !SubmitLoading }"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </span>
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
              <template v-for="(item,key) in Form.value" :key="key">
                <h3>{{$t(`LANG.${key}`)}}</h3>
                <div class="row mb-3" v-for="(seo,path) in item" :key="path">
                  <div class="col-md-3">
                    網址: {{path}}
                  </div>
                  <div class="col-md-8">
                    <template v-for="(value,name) in seo" :key="name">
                      {{ $t(`SEO.${name}`) }} :<input type="text" class="form-control mb-1" :placeholder="$t(`SEO.${name}`)" v-model="Form.value[key][path][name]">
                    </template>
                  </div>
                </div>
                <hr>
              </template>
            </form>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBtn,
} from "mdb-vue-ui-kit";
import Breadcrumbs from "@/components/Breadcrumbs";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { useI18n } from "vue-i18n";
import { ref, reactive } from "vue";

export default {
  name: "SeoManage",
  components: {
    MDBContainer,
    Breadcrumbs,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBBtn,
  },
  setup() {
    const SubmitLoading = ref(false);
    const i18n = useI18n();

    const Form = reactive({});

    // 取得SEO資訊
    const getSettings = async () => {
      return await ApiService.get(`Admin/v1/SeoKey`, '').then((response) => {
        if (response.status == 200) {
          Form.value = response.data.data;
        }
      });
    };

    // 資料送出
    const Submit = function() {
      SubmitLoading.value = true;
      ApiService.update(`Admin/v1/SeoKey`, 'update', Form.value).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "",
            text: `${i18n.t("COMMON.EDIT")}${i18n.t("COMMON.SUCCESS")}`,
            icon: "success",
            confirmButtonClass: i18n.t("COMMON.CONFIRM"),
          });
          SubmitLoading.value = false;
        }
      });
      return false;
    };

    return {
      getSettings,
      SubmitLoading,
      Form,
      Submit,
    };
  },
  async mounted() {
    Promise.all([await this.getSettings()]).then(() => {
      this.PageLoading = false;
    });
  },
};
</script>
